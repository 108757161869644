import { Component, computed, input, output } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { Item } from '../../../../../graphql/generated';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIcon } from '@ng-icons/core';
import { TooltipModule } from 'primeng/tooltip';
import { ImageDirective } from '../../../core/directives/image.directive';
import {
    CancelListingModalComponent
} from '../../pages/manage/supply/components/cancel-listing-modal/cancel-listing-modal.component';
import { ModalService } from '../../../core/services/modal.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
    selector: 'company-item-card',
    standalone: true,
    imports: [
        NgOptimizedImage,
        RouterLink,
        RouterLinkActive,
        NgIcon,
        TooltipModule,
        ImageDirective
    ],
    templateUrl: './item-card.component.html'
})
export class ItemCardComponent {
    public item = input.required<Item>();

    public listClick = output<Item>();
    public editClick = output<Item>();


    canManage = computed(() => {
        return this.authService.isAuthenticated() && this.authService.hasSubscriptionAccess();
    });


    constructor(
        private readonly modalService: ModalService,
        private readonly authService: AuthService
    ) {
    }

    public cancelListing() {
        this.modalService.open({
            component: CancelListingModalComponent,
            inputs: {
                item: this.item()
            }
        });
    }
}
