import { Injectable } from '@angular/core';
import { ModalService } from "./modal.service";
import { WizardComponent } from "../components/application/wizard/wizard.component";
import { WizardOptions } from "../../types/wizard/wizard-options";

@Injectable({
    providedIn: 'root'
})
export class WizardService {

    // ----------[ Dependencies ]----------

    constructor(
        private readonly modalService: ModalService,
    ) { }

    public open<T = any>(options: WizardOptions<T>): void {
        const id = this.modalService.open({
            component: WizardComponent,
            inputs: {
                title: options.title,
                steps: options.steps,
                initialize: options.initialize,
                complete: options.complete,
                service: options.context,
                close: (context: T) => {
                    if (options.onClose) {
                        options.onClose(context);
                    }
                    this.modalService.close(id);
                }
            },
            options: {
                closeOnBackdropClick: false
            }
        });
    }


}
