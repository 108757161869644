import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIcon } from '@ng-icons/core';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faWhatsapp,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { RouterLink } from '@angular/router';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faMapMarkerAlt, faMobile } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'shell-footer',
    standalone: true,
    imports: [
        NgOptimizedImage,
        FaIconComponent,
        NgIcon,
        RouterLink
    ],
    templateUrl: './footer.html'
})
export class Footer {
    protected readonly faFacebook = faFacebook;
    protected readonly faLinkedin = faLinkedin;
    protected readonly faYoutube = faYoutube;
    protected readonly faEnvelope = faEnvelope;
    protected readonly faPhone = faPhone;
    protected readonly faMobile = faMobile;
    protected readonly faMapMarkerAlt = faMapMarkerAlt;
    protected readonly faInstagram = faInstagram;
    protected readonly faWhatsapp = faWhatsapp;
}
