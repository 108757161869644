<div autoAnimate class="mb-5 bg-white">
    <app-collapsible-header [collapsed]="collapsed()" (collapsedChange)="toggleCollapse()">
        <div title class="mx-4 flex">
            <button type="button" cdkDragHandle [pTooltip]="orderTooltipContent">
                <ng-icon
                    name="heroBars3"
                    class="text-gray-500 hover:text-gray-900 hover:cursor-grab active:cursor-grabbing mr-4"
                    size="1.4em"
                />
                <ng-template #orderTooltipContent>
                    <div class="flex align-items-center text-xs">
                        <span>Sleep om de volgorde te wijzigen in je (publieke) inventaris</span>
                    </div>
                </ng-template>
            </button>
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{ category().pluralName }}
                ({{ category().items.length }})
            </h3>
        </div>
        <div actions class="flex items-center">
            <button
                type="button"
                class="flex items-center group"
                [pTooltip]="radiusTooltipContent"
                tooltipPosition="left"
                positionLeft="-10"
                (click)="onRadiusEditClick($event)"
            >
                <fa-icon [icon]="faBellRing" class="text-gray-500 group-hover:text-gray-900 mr-2" size="sm"></fa-icon>
                <span class="text-gray-400 group-hover:text-gray-900 font-normal text-sm mr-2">{{ category().radius ?? category().radius }}km</span>
                <ng-template #radiusTooltipContent>
                    <div class="flex align-items-center text-xs">
                        <span>Klik om de straal van de maximale afstand van meldingen in te stellen</span>
                    </div>
                </ng-template>
            </button>
            <span aria-hidden="true" class="mr-4 h-6 w-px bg-gray-200"></span>
        </div>
        <div content>
            <div class="gap-y-6 gap-x-4 grid grid-cols-2 max-w-screen-2xl mx-auto px-4 sm:grid-cols-3" autoAnimate>
                @for (item of category().items; track item.id) {
                    <company-item-card
                        (listClick)="listItem(item)"
                        [item]="item"
                    ></company-item-card>
                }
                <div>
                    <company-add-item-card
                        [companyId]="companyId()"
                        [categoryId]="category().id"
                    />
                </div>
            </div>
        </div>
    </app-collapsible-header>
</div>
