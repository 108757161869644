import {Component, input, OnInit} from '@angular/core';
import {icon, latLng, Map as LeafletMap, MapOptions, marker, tileLayer} from "leaflet";
import {LeafletModule} from "@bluehalo/ngx-leaflet";

@Component({
  selector: 'app-company-map',
  standalone: true,
    imports: [
        LeafletModule
    ],
  templateUrl: './company-map.component.html',
})
export class CompanyMapComponent implements OnInit {

    private map!: LeafletMap;
    private readonly tileSetId = "microsoft.base.road";
    private readonly language = "nl";
    private readonly subscriptionKey = "ujpxWBctxiymF4Q9xMrvjUmd6FXKLTnUghpF1n9WNS4";
    private readonly tileUrlMicrosoft = `https://atlas.microsoft.com/map/tile?api-version=2022-08-01&tilesetId=${this.tileSetId}&zoom={z}&x={x}&y={y}&language=${this.language}&subscription-key=${this.subscriptionKey}&tileSize=256`;

    public location = input<{
        latitude: number;
        longitude: number;
    }>();

    options: MapOptions = {
        zoomControl: false,
        layers: [tileLayer(this.tileUrlMicrosoft, {
            maxZoom: 18,
            attribution: '...'
        })],
        zoom: 12,
        center: latLng(
            this.location()?.latitude ?? 52.5,
            this.location()?.longitude ?? 4.8
        )
    };

    protected initializeMap(map: LeafletMap) {
        this.map = map;
    }

    ngOnInit() {
        this.options.center = latLng(
            this.location()?.latitude ?? 52.5,
            this.location()?.longitude ?? 4.8
        )
        this.options.layers?.push(
            marker(latLng(this.location()?.latitude ?? 0 , this.location()?.longitude ?? 0),{
                icon: icon({
                    iconUrl: 'assets/images/markers/marker-primary.svg',
                    iconSize: [25, 25]
                })
            })
        )

    }
}
