<div autoAnimate class="mb-4">
    <div
        class="border-b border-gray-200 pt-4 pb-2 mb-5 bg-white"
        (click)="toggleCollapsed()"
    >
        <div
            class="max-w-screen-2xl mx-auto sm:flex sm:items-center sm:justify-between pb-2 cursor-pointer">
            <ng-content select="[title]"></ng-content>

            <div class="flex">
                <ng-content select="[actions]"></ng-content>
                @if (collapsed()) {
                    <ng-icon
                        name="heroChevronUp"
                        class="text-gray-500 hover:text-gray-900 mt-1 mr-4"
                        size="1.4em"
                    ></ng-icon>
                } @else {
                    <ng-icon
                        name="heroChevronDown"
                        class="text-gray-500 hover:text-gray-900 mt-1 mr-4"
                        size="1.4em"
                    ></ng-icon>
                }
            </div>
        </div>
    </div>
    @if (!collapsed()) {
        <ng-content select="[content]"></ng-content>
    }
</div>
