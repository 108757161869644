<div class="lg:col-start-3 lg:row-end-1">
    <h2 class="sr-only">Summary</h2>
    <div class="">
        <dl class="flex flex-wrap pb-6">
            <div class="flex-auto pl-6 pt-6">
                <dt class="text-sm font-semibold leading-6 text-gray-900">Contactinformatie</dt>
                <dd class="text-base font-semibold leading-6 text-gray-900">{{ company().name }}</dd>
            </div>
<!--            <div class="flex-none self-end px-6 pt-4">-->
<!--                <dt class="sr-only">Status</dt>-->
<!--                <dd class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">-->
<!--                    Afgelopen dagen actief-->
<!--                </dd>-->
<!--            </div>-->
            @if(company().contactInformation?.phoneNumber) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Telefoonnummer</span>
                        <ng-icon name="heroPhone" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        <a href="tel:{{ company().contactInformation?.phoneNumber }}">
                            {{ company().contactInformation?.phoneNumber }}
                        </a>
                    </dd>
                </div>
            }
            @if(company().contactInformation?.planner?.phoneNumber) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Telefoonnummer planner</span>
                        <ng-icon name="heroPhone" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        <a href="tel:{{ company().contactInformation?.planner?.phoneNumber }}">
                            (Planner) {{ company().contactInformation?.planner?.phoneNumber }}
                        </a>
                    </dd>
                </div>
            }
            @if(company().contactInformation?.email) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">E-mailadres</span>
                        <ng-icon name="heroEnvelope" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        <a href="mailto:{{ company().contactInformation?.email }}">
                             {{ company().contactInformation?.email  }}
                        </a>
                    </dd>
                </div>
            }
            @if(company().website) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Website</span>
                        <ng-icon name="heroGlobeEuropeAfrica" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900 w-full text-wrap overflow-ellipsis pr-10">
                        <a target="_blank" [href]="company().website" class="w-full block truncate overflow-ellipsis">
                            {{ company().website }}
                        </a>
                    </dd>
                </div>
            }
            @if (company().location?.properties?.formattedAddress) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Adres</span>
                        <ng-icon name="heroMapPin" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        {{company().location?.properties?.formattedAddress }}
                    </dd>
                </div>
            } @else if (company().location?.properties?.address?.street) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Adres</span>
                        <ng-icon name="heroMapPin" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        {{company().location?.properties?.address?.street }} {{company().location?.properties?.address?.houseNumber }},
                        {{company().location?.properties?.address?.postcode }} {{company().location?.properties?.address?.city }}
                    </dd>
                </div>

            } @else if (company().location?.properties?.address?.city) {
                <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt class="flex-none">
                        <span class="sr-only">Plaats</span>
                        <ng-icon name="heroMapPin" class="h-5 w-5 text-gray-900 mt-0.5"></ng-icon>
                    </dt>
                    <dd class="text-md font-medium leading-6 text-gray-900">
                        {{ company().location?.properties?.address?.city }}
                    </dd>
                </div>
            }
        </dl>
        @if(showMap()) {
            <div class="border-t border-gray-900/5 h-72 rounded-b-lg overflow-hidden">
                <app-company-map
                    [location] = "companyLocation()"
                >
                </app-company-map>
            </div>
        }
    </div>
</div>
