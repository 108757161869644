import {Component, input} from '@angular/core';
import {CancelListingGQL, Company, CompanyByIdGQL, Item} from "../../../../../../../../graphql/generated";
import {ModalService} from "../../../../../../core/services/modal.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'company-manage-supply-cancel-listing-modal',
  standalone: true,
    imports: [
        DatePipe
    ],
  templateUrl: './cancel-listing-modal.component.html',
})
export class CancelListingModalComponent {
    item = input<Item | null>(null);

    constructor(
        private readonly modalService: ModalService,
        private readonly cancelListingGQL: CancelListingGQL,
        private readonly companyByIdGQL: CompanyByIdGQL
    ) {
    }

    public cancelListing() {
        const companyId = this.item()?.company.id;

        this.cancelListingGQL.mutate(
            {itemId: this.item()?.id},
            {
                refetchQueries: [
                    {
                        query: this.companyByIdGQL.document,
                        variables: {id: companyId}
                    }
                ],
                awaitRefetchQueries: true
            }
        ).subscribe({
            next: () => {
                this.modalService.close();
            },
            error: (error) => {
                console.error('Error cancelling listing:', error);
                // Handle error (e.g., show error message to user)
            }
        });
    }

    cancel() {
        this.modalService.close();
    }
}
