<!-- Filters -->
<section aria-labelledby="filter-heading">
    <h2 id="filter-heading" class="sr-only">Filters</h2>

    <div class="bg-white pb-4">
        <div class="mx-auto flex max-w-screen-2xl justify-between px-4 sm:px-4 lg:px-4">
            <div class="relative inline-block text-left">
                <div>
                    <button
                        (click)="toggleAllCollapsed.emit()"
                        type="button"
                        class="relative inline-flex items-center gap-x-1.5 py-2 rounded-sm text-sm font-normal text-gray-500 hover:underline underline-offset-2">
                        <ng-icon name="heroChevronUpDown" class="text-gray-500" size="1.2em"></ng-icon>
                        @if (allCollapsed())
                        {
                            Alles uitklappen
                        } @else {
                            Alles inklappen
                        }
                    </button>
                </div>
            </div>

            @if (showSearch()) {
                <div class="mt-3 sm:ml-4 sm:mt-0">
                    <label for="mobile-search-candidate" class="sr-only">Zoeken</label>
                    <label for="desktop-search-candidate" class="sr-only">Zoeken</label>
                    <div class="flex rounded-sm">
                        <div class="relative flex-grow focus-within:z-10  shadow-sm">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                                     aria-hidden="true" data-slot="icon">
                                    <path fill-rule="evenodd"
                                          d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
                                          clip-rule="evenodd"/>
                                </svg>
                            </div>
                            <input type="text" name="mobile-search-candidate" id="mobile-search-candidate"
                                   class=" shadow-sm block w-full rounded-sm border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
                                   placeholder="Search">
                            <input type="text" name="desktop-search-candidate" id="desktop-search-candidate"
                                   class="hidden w-full rounded-sm border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
                                   [(ngModel)]="searchQuery"
                                   placeholder="Zoek in inventaris">
                        </div>
<!--                        <button type="button"-->
<!--                                class="relative shadow-sm -ml-px inline-flex items-center gap-x-1.5 rounded-r-sm px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">-->
<!--                            <svg class="-ml-0.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"-->
<!--                                 aria-hidden="true" data-slot="icon">-->
<!--                                <path fill-rule="evenodd"-->
<!--                                      d="M2 3.75A.75.75 0 0 1 2.75 3h11.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 7.5a.75.75 0 0 1 .75-.75h6.365a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 7.5ZM14 7a.75.75 0 0 1 .55.24l3.25 3.5a.75.75 0 1 1-1.1 1.02l-1.95-2.1v6.59a.75.75 0 0 1-1.5 0V9.66l-1.95 2.1a.75.75 0 1 1-1.1-1.02l3.25-3.5A.75.75 0 0 1 14 7ZM2 11.25a.75.75 0 0 1 .75-.75H7A.75.75 0 0 1 7 12H2.75a.75.75 0 0 1-.75-.75Z"-->
<!--                                      clip-rule="evenodd"/>-->
<!--                            </svg>-->
<!--                            Sorteren-->
<!--                            <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor"-->
<!--                                 aria-hidden="true" data-slot="icon">-->
<!--                                <path fill-rule="evenodd"-->
<!--                                      d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"-->
<!--                                      clip-rule="evenodd"/>-->
<!--                            </svg>-->
<!--                        </button>-->

                        <!-- vertical line -->
                        <div class="h-8 mt-0.5 border-gray-200 border-l ml-4"></div>

                        <button
                            (click)="addItem()"
                            type="button"
                                class="shadow-sm relative ml-4 inline-flex items-center gap-x-1.5 rounded-sm px-3 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-blue-500 hover:bg-gray-50">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="-ml-0.5 h-5 w-5 text-blue-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>

                            Voeg inventaris toe
                        </button>
                    </div>
                </div>

            } @else {

                <!-- Mobile filter dialog toggle, controls the 'mobileFiltersOpen' state. -->
                <button type="button"
                        class="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden">
                    Filters
                </button>

                <div class="hidden sm:block">
                    <div class="flow-root">
                        <div class="-mx-4 flex items-center divide-x divide-gray-200">
                            <div class="relative inline-block px-4 text-left">
                                <app-dropdown>
                                    <dropdown-trigger>
                                        <button
                                            type="button"
                                            class="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
                                            aria-expanded="false">
                                            <span>Filter op aanbiedstatus</span>
                                            <svg
                                                class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                        </button>
                                    </dropdown-trigger>
                                    <dropdown-content>

                                    </dropdown-content>
                                </app-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    </div>
</section>
