@if (item().status?.isListedAndAvailableInFuture) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
      <svg class="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
        <span>Aangeboden
            @if (item().listing?.validity[0]) {
                vanaf {{ item().listing?.validity[0] | date: 'dd-MM-yyyy' }}
            }
            @if (item().listing?.validity[1]) {
                tot {{ item().listing?.validity[1] | date: 'dd-MM-yyyy' }}
            }
        </span>
    </span>
}
@if (item().status?.isListedAndAvailable && !item().status?.expiresToday && item().status?.neverExpires) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
      <svg class="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
        <span>Beschikbaar voor onbeperkte tijd</span>
    </span>
} @else if (item().status?.isListedAndAvailable && !item().status?.expiresToday && !item().status?.neverExpires) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
        <span>Aangeboden tot {{ item().listing?.validity[1] | date: 'dd-MM-yyyy' }}</span>
    </span>
} @else if (item().status?.isListedAndAvailable && item().status?.expiresToday) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700">
      <svg class="h-1.5 w-1.5 fill-orange-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
        Verloopt vandaag
    </span>
} @else if (!item().status?.isListedAndAvailableInFuture) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
      Niet aangeboden
    </span>
}
@if (!item().status?.neverExpires) {
    <span class="text-red"></span>
}

@if (item().status?.isListed && item().availability[0]) {
    <span class="ml-1.5 inline-flex items-center gap-x-1.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700">
        <span>Beschikbaar vanaf {{ item().availability[0] | date: 'dd-MM-yyyy' }}</span>
    </span>
}
@if (item().status?.isListed && item().availability[1]) {
    <span class="ml-1.5 inline-flex items-center gap-x-1.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700">
        <span>Beschikbaar tot {{ item().availability[1] | date: 'dd-MM-yyyy' }}</span>
    </span>
}
