<footer class="bg-white pb-10 px-6 2xl:px-0" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-screen-2xl">
        <div class="mb-16 border-t border-gray-900/10 pt-8"></div>
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div class="text-center lg:text-left">
                <img height="16" width="200" [ngSrc]="'/assets/images/logo_horizontal_black.svg'" alt="Planmeister" priority class="mx-auto lg:mx-0 mb-8">
            </div>
            <div class="col-span-1 lg:col-span-2">
                <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                    <div>
                        <h3 class="text-md font-semibold leading-6 text-gray-900 text-center lg:text-left">Contact</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li class="flex items-center justify-center lg:justify-start">
                                <fa-icon [icon]="faEnvelope" [size]="'sm'" class="text-gray-400 mr-2"></fa-icon>
                                <a href="mailto:info@planmeister.com" class="text-md leading-6 text-gray-500 hover:text-gray-700 transition-colors duration-300 hover:underline">
                                    info&#64;planmeister.com
                                </a>
                            </li>
                            <li class="flex items-center justify-center lg:justify-start">
                                <fa-icon [icon]="faPhone" [size]="'sm'" class="text-gray-400 mr-2"></fa-icon>
                                <a href="tel:+31850044938" class="text-md leading-6 text-gray-500 hover:text-gray-700 transition-colors duration-300 hover:underline">
                                    +31 85 004 49 38
                                </a>
                            </li>
                            <li class="flex items-center justify-center lg:justify-start">
                                <fa-icon [icon]="faWhatsapp" [size]="'sm'" class="text-gray-400 mr-2"></fa-icon>
                                <a href="https://wa.me/31621312322" class="text-md leading-6 text-gray-500 hover:text-gray-700 transition-colors duration-300 hover:underline">
                                    +31 6 21 31 23 22
                                </a>
                            </li>
                            <li class="flex items-start justify-center lg:justify-start">
                                <fa-icon [icon]="faMapMarkerAlt" [size]="'sm'" class="text-gray-400 mr-2 mt-1"></fa-icon>
                                <a href="https://www.google.com/maps/place/Planmeister/@53.1342327,7.0512449,19.34z/data=!4m15!1m8!3m7!1s0x47b7cda8c7ac2d89:0x2ca5b64c0634eae6!2sHoogebrug,+A.J.+Romijnweg+23,+9672+AH+Winschoten!3b1!8m2!3d53.1342993!4d7.0512155!16s%2Fg%2F11c213z28p!3m5!1s0x47b7cdcef976a2eb:0xea9154a3d876f3c4!8m2!3d53.1344049!4d7.0512556!16s%2Fg%2F11sbp3lw_s?entry=ttu&g_ep=EgoyMDI0MTAwMS4wIKXMDSoASAFQAw%3D%3D"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   class="text-md leading-6 text-gray-500 hover:text-gray-700 transition-colors duration-300 hover:underline text-center lg:text-left">
                                    A.J. Romijnweg 23<br>9672AH Winschoten
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="text-md font-semibold leading-6 text-gray-900 text-center lg:text-left">Algemeen</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li class="text-center lg:text-left">
                                <a href="/"
                                   class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Word Planmeister</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a href="/"
                                   class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Homepagina</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/netwerkavonden'"
                                   class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Netwerkavonden</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/contact'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Contact opnemen</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="text-md font-semibold leading-6 text-gray-900 text-center lg:text-left">Planmeister</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/vraag'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Actuele vraag</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/aanbod'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Actueel aanbod</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/bedrijf/beheer/instellingen/informatie'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Mijn account</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 class="text-md font-semibold leading-6 text-gray-900 text-center lg:text-left">Juridisch</h3>
                        <ul role="list" class="mt-6 space-y-4">
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/algemene-voorwaarden'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Algemene voorwaarden</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/algemene-voorwaarden'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Cookieverklaring</a>
                            </li>
                            <li class="text-center lg:text-left">
                                <a [routerLink]="'/algemene-voorwaarden'" class="text-md leading-6 text-gray-600 hover:text-gray-900 hover:underline">Privacyverklaring</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-16 border-t border-gray-900/10 pt-8 flex flex-col items-center lg:flex-row lg:justify-between">
            <div class="flex justify-center space-x-6 mb-4 lg:mb-0 lg:order-2">
                <a href="https://www.facebook.com/Planmeister.nl" target="_blank" class="text-gray-400 hover:text-blue-600">
                    <span class="sr-only">Facebook</span>
                    <fa-icon [icon]="faFacebook" size="lg"></fa-icon>
                </a>
                <a href="https://nl.linkedin.com/company/planmeisterpuntcom/" target="_blank" class="text-gray-400 hover:text-blue-500">
                    <span class="sr-only">LinkedIn</span>
                    <fa-icon [icon]="faLinkedin" size="lg"></fa-icon>
                </a>
                <a href="https://www.youtube.com/channel/UCKKHaDmfUyLpyXoT24l2wNA" target="_blank" class="text-gray-400 hover:text-red-500">
                    <span class="sr-only">YouTube</span>
                    <fa-icon [icon]="faYoutube" size="lg"></fa-icon>
                </a>
                <a href="https://www.instagram.com/planmeistergww/" target="_blank" class="text-gray-400 hover:text-purple-500">
                    <span class="sr-only">Instagram</span>
                    <fa-icon [icon]="faInstagram" size="lg"></fa-icon>
                </a>
            </div>
            <p class="text-xs leading-5 text-gray-500 text-center lg:text-left lg:order-1">&copy; 2024 Planmeister - Alle rechten voorbehouden.</p>
        </div>
    </div>
</footer>
