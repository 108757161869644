import {Component, input} from '@angular/core';
import {Item} from "../../../../../../../../graphql/generated";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'company-public-item-badge',
  standalone: true,
    imports: [
        DatePipe
    ],
  templateUrl: './public-item-badge.component.html',
  styleUrl: './public-item-badge.component.scss'
})
export class PublicItemBadgeComponent {
    public item = input.required<Item>();
}
