import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CategoryConfiguration, Item } from '../../../../../../graphql/generated';
import { map } from 'rxjs/operators';
import { InventoryCategory, InventoryCategoryBuilder } from '../../../inventory-category.builder';

@Injectable({
    providedIn: 'root'
})
export class InventoryCategoryService {
    private categoriesSubject = new BehaviorSubject<InventoryCategory[]>([]);
    private orderedCategoriesSubject = new BehaviorSubject<InventoryCategory[]>([]);
    private collapsedCategoriesSubject = new BehaviorSubject<Set<string>>(new Set());

    categories$: Observable<InventoryCategory[]> = this.categoriesSubject.asObservable();
    orderedCategories$: Observable<InventoryCategory[]> = this.orderedCategoriesSubject.asObservable();
    allCollapsed$: Observable<Set<string>> = this.collapsedCategoriesSubject.asObservable();

    getCollapsedState(): Set<string> {
        return new Set(this.collapsedCategoriesSubject.value);
    }
    private isInitialized = false;

    initializeService(items: Item[], categoryConfigurations: CategoryConfiguration[]) {
        const categories = InventoryCategoryBuilder.buildInventoryCategories(items, categoryConfigurations);
        const nonEmptyCategories = categories.filter(category => category.items.length > 0);
        this.categoriesSubject.next(nonEmptyCategories);
        this.orderedCategoriesSubject.next(InventoryCategoryBuilder.orderCategories(nonEmptyCategories));
        // Initialize with all categories collapsed
        this.collapsedCategoriesSubject.next(new Set(nonEmptyCategories.map(c => c.id)));
        this.isInitialized = true;
    }

    reinitializeService(items: Item[], categoryConfigurations: CategoryConfiguration[]) {
        const currentCollapsedState = this.getCollapsedState();
        const categories = InventoryCategoryBuilder.buildInventoryCategories(items, categoryConfigurations);
        const nonEmptyCategories = categories.filter(category => category.items.length > 0);
        this.categoriesSubject.next(nonEmptyCategories);
        this.orderedCategoriesSubject.next(InventoryCategoryBuilder.orderCategories(nonEmptyCategories));

        // Preserve the collapsed state for existing non-empty categories
        const newCollapsedState = new Set(
            nonEmptyCategories
                .filter(category => currentCollapsedState.has(category.id))
                .map(category => category.id)
        );
        this.collapsedCategoriesSubject.next(newCollapsedState);
    }

    updateInventoryState(items: Item[], categoryConfigurations: CategoryConfiguration[]) {
        if (!this.isInitialized) {
            this.initializeService(items, categoryConfigurations);
        } else {
            this.reinitializeService(items, categoryConfigurations);
        }
    }

    moveCategory(event: CdkDragDrop<InventoryCategory[]>) {
        const categories = this.orderedCategoriesSubject.value.slice();
        const [ reorderedItem ] = categories.splice(event.previousIndex, 1);
        categories.splice(event.currentIndex, 0, reorderedItem);
        categories.forEach((category, index) => {
            category.order = index;
        });
        this.orderedCategoriesSubject.next(categories);
    }

    toggleAllCategories() {
        const allCategories = this.orderedCategoriesSubject.value;
        const currentCollapsed = this.collapsedCategoriesSubject.value;

        if (this.areAllCategoriesCollapsed()) {
            // If all are collapsed, open all
            this.collapsedCategoriesSubject.next(new Set());
        } else {
            // If not all are collapsed, collapse all
            this.collapsedCategoriesSubject.next(new Set(allCategories.map(c => c.id)));
        }
    }

    areAllCategoriesCollapsed(): boolean {
        const allCategories = this.orderedCategoriesSubject.value;
        const collapsedCategories = this.collapsedCategoriesSubject.value;
        return allCategories.length > 0 && allCategories.every(category => collapsedCategories.has(category.id));
    }

    areAllCategoriesOpen(): boolean {
        return this.orderedCategoriesSubject.value.length > 0 && this.collapsedCategoriesSubject.value.size === 0;
    }

    getNonEmptyCategoryCount(): number {
        return this.orderedCategoriesSubject.value.length;
    }

    isCollapsed(id: string): Observable<boolean> {
        return this.collapsedCategoriesSubject.pipe(
            map(collapsedSet => collapsedSet.has(id)),
            distinctUntilChanged()
        );
    }

    toggleCategory(id: string) {
        const collapsedCategories = new Set(this.collapsedCategoriesSubject.value);

        if (collapsedCategories.has(id)) {
            collapsedCategories.delete(id);
        } else {
            collapsedCategories.add(id);
        }

        this.collapsedCategoriesSubject.next(collapsedCategories);
    }

    getCategorySequence(): Record<string, number> {
        return this.orderedCategoriesSubject.value.reduce((acc, category, index) => {
            acc[category.id] = index;
            return acc;
        }, {} as Record<string, number>);
    }
}
