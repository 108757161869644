import { Component, computed } from '@angular/core';
import { AddItemWizardContext } from '../add-item-wizard-context.service';
import { CategoryByIdGQL } from '../../../../../../graphql/generated';
import { map, of, switchMap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { compareProperty } from '../../../../utils/property-utils';
import { LoadingStateComponent } from '../../../../core/loading-state/loading-state.component';

@Component({
    selector: 'company-add-item-overview',
    standalone: true,
    imports: [
        LoadingStateComponent
    ],
    templateUrl: './add-item-overview.component.html'
})
export class AddItemOverviewComponent {

    // ----------[ Observables ]----------

    protected readonly categoryId$ = this.context.observe('categoryId');

    // ----------[ Computed ]----------

    protected readonly equipmentNumber = this.context.select('equipmentNumber');
    protected readonly description = this.context.select('description');
    protected readonly location = this.context.select('location');
    protected readonly propertyValues = this.context.select('properties');

    protected readonly category = toSignal(this.categoryId$.pipe(
        switchMap(id => id ? this.categoryByIdGQL.watch({ id }).valueChanges : []),
        map(result => result.data.categoryById)
    ));

    protected readonly selectedProperties = computed(() => {
        const propertyValues = this.propertyValues();
        const category = this.category();

        if (!category) {
            return [];
        }

        return propertyValues
            .map(value => {
                const property = category.properties.find(p => compareProperty(p.id, value));

                if (!property) {
                    return null;
                }

                switch (property.__typename) {
                    case 'PredefinedValueProperty':
                        return {
                            name: property.name,
                            value: property.values
                                .filter(v => value.predefined?.predefinedValueIds.includes(v.id))
                                .map(v => v.name)
                                .join(', ')
                        };
                    case 'BooleanProperty':
                        return {
                            name: property.name,
                            value: value.boolean ? 'Ja' : 'Nee'
                        };
                    case 'NumericProperty':
                        return {
                            name: property.name,
                            value: `${value.numeric?.numericValue} ${property.unit}`
                        };
                    default:
                        return null;
                }
            })
            .filter(Boolean);
    })

    // ----------[ Dependencies ]----------

    constructor(
        private readonly context: AddItemWizardContext,
        private readonly categoryByIdGQL: CategoryByIdGQL
    ) {
    }

}
