import { Component, OnInit } from '@angular/core';
import { ItemPropertiesFormComponent } from "../../../components/item-properties-form/item-properties-form.component";
import { AddItemWizardContext } from "../add-item-wizard-context.service";
import { PropertyValueInput } from '../../../../../../graphql/generated';
import { JsonPipe } from '@angular/common';

@Component({
    selector: 'app-add-item-properties',
    standalone: true,
    imports: [
        ItemPropertiesFormComponent,
        JsonPipe
    ],
    templateUrl: './add-item-properties.component.html'
})
export class AddItemPropertiesComponent implements OnInit {

    protected categoryId = this.context.select('categoryId');
    protected properties: PropertyValueInput[] = [];

    constructor(
        private readonly context: AddItemWizardContext
    ) {}

    ngOnInit() {
        this.properties = this.context.value('properties') || [];
    }

    protected onPropertiesChange(newProperties: PropertyValueInput[]) {
        this.properties = newProperties;
        this.context.set('properties', newProperties);
    }
}
