import { Component, input, model } from '@angular/core';
import { PrimaryButtonComponent } from "../../../core/components/buttons/primary-button/primary-button.component";
import {
    BooleanProperty,
    CategoryByIdGQL,
    NumericProperty,
    PredefinedValue,
    PredefinedValueProperty,
    PropertyValueInput
} from "../../../../../graphql/generated";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, switchMap } from "rxjs";
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'company-item-properties-form',
    standalone: true,
    imports: [
        PrimaryButtonComponent,
        InputNumberModule,
        FormsModule
    ],
    templateUrl: './item-properties-form.component.html',
    styleUrl: './item-properties-form.component.scss'
})
export class ItemPropertiesFormComponent {

    public readonly categoryId = input.required<string>();
    public readonly properties = model<PropertyValueInput[]>([]);

    protected readonly category = toSignal(
        toObservable(this.categoryId).pipe(
            switchMap(id => this.categoryById.watch({ id }).valueChanges),
            map(result => result.data.categoryById)
        )
    );

    constructor(private readonly categoryById: CategoryByIdGQL) {}

    protected onSelectPredefinedValueProperty(property: PredefinedValueProperty, option: PredefinedValue) {
        this.properties.update(properties => {
            const index = properties.findIndex(p => p.predefined?.propertyId === property.id);
            if (index === -1) {
                return [...properties, {
                    predefined: {
                        propertyId: property.id,
                        predefinedValueIds: [option.id]
                    }
                }];
            }

            const updatedProperties = [...properties];
            const value = updatedProperties[index].predefined!;

            if (property.inputOptions.isMultiple) {
                value.predefinedValueIds = value.predefinedValueIds.includes(option.id)
                    ? value.predefinedValueIds.filter(id => id !== option.id)
                    : [...value.predefinedValueIds, option.id];
            } else {
                value.predefinedValueIds = [option.id];
            }

            return updatedProperties;
        });
    }

    protected isPredefinedValuePropertySelected(property: PredefinedValueProperty, option: PredefinedValue) {
        return this.properties().find(p => p.predefined?.propertyId === property.id)
            ?.predefined?.predefinedValueIds.includes(option.id) ?? false;
    }

    protected isAnyPredefinedValuePropertySelected(property: PredefinedValueProperty) {
        return !!this.properties().find(p => p.predefined?.propertyId === property.id)
            ?.predefined?.predefinedValueIds.length;
    }

    protected onChangeNumericProperty(property: NumericProperty, value: number | null) {
        this.properties.update((properties:any) => {
            const index = properties.findIndex((p:any) => p.numeric?.propertyId === property.id);
            if (index === -1) {
                return [...properties, { numeric: { propertyId: property.id, numericValue: value } }];
            }
            const updatedProperties = [...properties];
            updatedProperties[index] = { ...updatedProperties[index], numeric: { propertyId: property.id, numericValue: value } };
            return updatedProperties;
        });
    }

    protected numericPropertyValue(property: NumericProperty): number | null {
        return this.properties().find(p => p.numeric?.propertyId === property.id)?.numeric?.numericValue ?? null;
    }

    protected isNumericPropertySet(property: NumericProperty): boolean {
        const value = this.numericPropertyValue(property);
        return value !== null && value !== undefined;
    }

    protected booleanPropertyValue(property: BooleanProperty) {
        return this.properties().find(p => p.boolean?.propertyId === property.id)?.boolean?.booleanValue ?? false;
    }

    protected onChangeBooleanProperty(property: BooleanProperty, event: Event) {
        this.properties.update(properties => {
            const index = properties.findIndex(p => p.boolean?.propertyId === property.id);
            const value = (event.target as HTMLInputElement).checked;
            if (index === -1) {
                return [...properties, { boolean: { propertyId: property.id, booleanValue: value } }];
            }
            const updatedProperties = [...properties];
            updatedProperties[index] = { ...updatedProperties[index], boolean: { propertyId: property.id, booleanValue: value } };
            return updatedProperties;
        });
    }
}
