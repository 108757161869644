<div class="px-6 pt-4 pb-6">
    <h2 class="text-base font-semibold leading-7 text-gray-900">Selecteer een categorie</h2>
    <p class="mt-1 text-sm leading-6 text-gray-500">Selecteer een categorie voor het item dat je wilt toevoegen.</p>
    <label for="category-dropdown" class="block text-sm font-medium text-gray-700 mt-4 mb-2">
        Categorie
    </label>
    <app-category-search-dropdown
        id="category-dropdown"
        [initial]="categoryId()"
        (selected)="onCategorySelected($event)"
        placeholder="Zoek een categorie"
    />
</div>
