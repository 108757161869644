import { Component, model } from '@angular/core';
import {
    AddItemCardComponent
} from '../../../../company/components/add-item-card/add-item-card.component';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { ItemCardComponent } from '../../../../company/components/item-card/item-card.component';
import { NgIcon } from '@ng-icons/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-collapsible-header',
    standalone: true,
    imports: [
        AddItemCardComponent,
        AutoAnimateDirective,
        ItemCardComponent,
        NgIcon,
        NgClass
    ],
    templateUrl: './collapsible-header.component.html'
})
export class CollapsibleHeaderComponent {
    collapsed = model(false);

    toggleCollapsed() {
        this.collapsed.set(!this.collapsed());
    }
}
