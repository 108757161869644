import { FormsModule } from '@angular/forms';
import { WizardService } from '../../../core/services/wizard.service';
import { addItemWizard } from '../../wizards/add-item/add-item.wizard';
import { Component, CUSTOM_ELEMENTS_SCHEMA, input, model, output } from '@angular/core';
import {
    DropdownComponent
} from '../../../core/components/application/dropdown/dropdown.component';
import { NgIcon } from '@ng-icons/core';
import { CompanyService } from '../../services/company.service';

@Component({
    selector: 'category-manage-inventory-filter-bar',
    standalone: true,
    imports: [
        FormsModule,
        DropdownComponent,
        NgIcon
    ],
    templateUrl: './manage-inventory-bar.component.html',
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ManageInventoryBarComponent {

    showSearch = input<boolean>(false);

    searchQuery = model<string>('');

    allCollapsed = input<boolean>(false);
    toggleAllCollapsed = output<void>();

    constructor(
        private readonly wizardService: WizardService,
        private readonly companyService: CompanyService
    ) {
    }

    protected addItem() {
        this.wizardService.open(
            addItemWizard({
                companyId: this.companyService.value('companyId')
            })
        );
    }
}
