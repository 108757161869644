import {Component, input} from '@angular/core';
import {Item} from "../../../../../../../graphql/generated";
import {NgIcon} from "@ng-icons/core";
import {NgOptimizedImage} from "@angular/common";
import {ItemStatusBadgesComponent} from "../../../../components/item-status-badges/item-status-badges.component";
import {PublicItemBadgeComponent} from "./public-item-badge/public-item-badge.component";
import { ImageDirective } from '../../../../../core/directives/image.directive';

@Component({
  selector: 'company-public-item',
  standalone: true,
    imports: [
        NgIcon,
        NgOptimizedImage,
        ItemStatusBadgesComponent,
        PublicItemBadgeComponent,
        ImageDirective
    ],
  templateUrl: './public-item.component.html',
  styleUrl: './public-item.component.scss'
})
export class PublicItemComponent {
    public item = input.required<Item>();
}
