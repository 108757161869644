import { Component, input, OnInit, output } from '@angular/core';
import { ModalPanelComponent } from '../../../core/components/modal-panel/modal-panel.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryCategory } from '../../inventory-category.builder';
import {
    CompanyByIdGQL,
    ConfigureCategoryGQL,
    ConfigureCategoryInput
} from '../../../../../graphql/generated';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-edit-category-range-modal',
    standalone: true,
    imports: [
        ModalPanelComponent,
        InputNumberModule,
        FormsModule,
        ReactiveFormsModule,
        NgClass
    ],
    templateUrl: './edit-category-range-modal.component.html'
})
export class EditCategoryRangeModalComponent implements OnInit {
    public category = input.required<InventoryCategory>();
    public companyId = input.required<string>();
    public onClose = output<boolean>();
    formGroup!: FormGroup;
    isLoading: boolean = false;

    constructor(
        private readonly configureCategoryGQL: ConfigureCategoryGQL,
        private readonly companyByIdGQL: CompanyByIdGQL
    ) {
    }

    onSubmit() {
        this.isLoading = true;
        const configureCategoryInput: ConfigureCategoryInput = {
            categoryId: this.category().id,
            companyId: this.companyId(),
            radius: this.formGroup.get('radius')?.value
        }
        this.configureCategoryGQL.mutate(
            { input: configureCategoryInput },
            {
                refetchQueries: [
                    {
                        query: this.companyByIdGQL.document,
                        variables: { id: this.companyId() }
                    }
                ]
            }
        ).subscribe({
            next: () => {
                this.onClose.emit(true);
            },
            error: (error: any) => {
                console.error(error);
            }
        })

    }

        ngOnInit()
        {
            console.log(this.category().radius);
            this.formGroup = new FormGroup({
                radius: new FormControl(this.category().radius)
            });
        }


    }
