import {
    ChangeDetectionStrategy,
    Component,
    input,
    OnDestroy,
    OnInit,
    signal
} from '@angular/core';
import { ItemCardComponent } from '../item-card/item-card.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Item } from '../../../../../graphql/generated';
import { NgIcon } from '@ng-icons/core';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { AddItemCardComponent } from '../add-item-card/add-item-card.component';
import {
    CollapsibleHeaderComponent
} from '../../../core/components/menus/collapsible-header/collapsible-header.component';
import { ModalService } from '../../../core/services/modal.service';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'primeng/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBellRing } from '@fortawesome/pro-light-svg-icons';
import {
    ListItemModalComponent
} from '../../pages/manage/supply/components/list-item-modal/list-item-modal.component';
import {
    InventoryCategoryService
} from '../../pages/manage/inventory/inventory-category.service';
import { Subject, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { InventoryCategory } from '../../inventory-category.builder';
import {
    EditCategoryRangeModalComponent
} from '../edit-category-range-modal/edit-category-range-modal.component';

@Component({
    selector: 'company-manage-category-card',
    standalone: true,
    imports: [
        ItemCardComponent,
        RouterLink,
        RouterLinkActive,
        NgIcon,
        AutoAnimateDirective,
        AddItemCardComponent,
        CollapsibleHeaderComponent,
        CdkDragHandle,
        TooltipModule,
        FaIconComponent,
        AsyncPipe
    ],
    templateUrl: './company-manage-category-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyManageCategoryCardComponent implements OnInit, OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    public readonly companyId = input.required<string>();
    public category = input.required<InventoryCategory>();

    collapsed = signal<boolean>(true);

    ngOnInit() {
        this.categoryService
            .isCollapsed(this.category().id)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(collapsed => this.collapsed.set(collapsed));
    }

    toggleCollapse() {
        this.categoryService.toggleCategory(this.category().id);
    }

    constructor(
        private readonly modalService: ModalService,
        private categoryService: InventoryCategoryService
    ) {
    }

    protected readonly faBellRing = faBellRing;

    listItem(item: Item) {
        this.modalService.open({
            component: ListItemModalComponent,
            inputs: {
                item: item
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onRadiusEditClick(event:Event) {
        event.stopPropagation();
       const modalId =  this.modalService.open({
            component: EditCategoryRangeModalComponent,
            inputs: {
                companyId: this.companyId(),
                category: this.category()
            },
            outputs: {
                onClose: () => {
                        this.modalService.close(modalId);
                }
            }
        });
    }
}
