<div class="px-6 pt-4 pb-6">
    @if (categoryId(); as categoryId) {
        <company-item-properties-form
            [categoryId]="categoryId"
            [properties]="properties"
            (propertiesChange)="onPropertiesChange($event)"
        />
    } @else {
        <p>No category selected.</p>
    }
</div>
