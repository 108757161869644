@if (item().status?.isListedAndAvailable) {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
        <span>Aangeboden</span>
    </span>
}
@else {
    <span class="inline-flex items-center gap-x-1.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700">
      <svg class="h-1.5 w-1.5 fill-gray-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3"/>
      </svg>
      Niet aangeboden
    </span>
}
