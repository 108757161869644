import { Component } from '@angular/core';

@Component({
    selector: 'app-mobile-screen-blocker',
    templateUrl: './mobile-screen-blocker.component.html',
    standalone: true
})

export class MobileScreenBlockerComponent {

}

