
<div class="p-6  mx-auto">
    <div class="flex justify-between items-center mb-6">
        <img src="assets/images/logo_horizontal_black.svg">
    </div>

    <div class="flex flex-col items-center gap-3">
        <div class="text-center">
            <h1 class="text-black font-brand text-2xl">Download nu de Planmeister app</h1>
            <p class="text-black mt-4">Om Planmeister te gebruiken op je mobiel, download de app.</p>
        </div>

        <div class="flex flex-col items-center w-full gap-4">
            <a href="https://apps.apple.com/us/app/planmeister/id1589200546" class="w-52">
                <img src="/assets/images/homepage/download_appstore.svg" alt="Download on the App Store" class="w-full h-full object-contain">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.planmeister.app&pcampaignid=web_share" class="w-60">
                <img src="/assets/images/homepage/download_playstore.png" alt="Get it on Google Play" class="w-full h-full object-contain">
            </a>
        </div>
    </div>
</div>
