import {Component, computed, input} from '@angular/core';
import {Company} from "../../../../../../graphql/generated";
import {NgIcon} from "@ng-icons/core";
import {CompanyMapComponent} from "../company-map/company-map.component";

@Component({
  selector: 'company-contact-information',
  standalone: true,
    imports: [
        NgIcon,
        CompanyMapComponent
    ],
  templateUrl: './company-contact-information.component.html',
})
export class CompanyContactInformationComponent {
    company = input.required<Company>();

    showMap = input<boolean>(false);

    companyLocation = computed(() => {
        const coordinates = this.company().location?.geometry?.coordinates;
        return {
            latitude: coordinates ? coordinates[1] : 0,
            longitude: coordinates ? coordinates[0] : 0
        };
    });
}
