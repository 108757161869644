import {
    AfterViewInit,
    Component,
    computed,
    effect,
    Injector,
    input,
    OnDestroy,
    output,
    runInInjectionContext,
    viewChild,
    ViewContainerRef,
    OutputRef
} from '@angular/core';
import { NgComponentOutlet } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-dynamic-component',
    standalone: true,
    imports: [NgComponentOutlet],
    template: `<ng-container #dynamicComponentContainer></ng-container>`
})
export class DynamicDialogContentComponent<
    T extends new (...args: any[]) => any = any
> implements AfterViewInit, OnDestroy {
    // ----------[ Inputs ]----------

    public readonly component = input<T>();
    public readonly component$ = toObservable(this.component);

    public readonly inputs = input.required<any>();
    public readonly outputs = input<Record<string, (...args: any[]) => void>>();

    // ----------[ Outputs ]----------

    public readonly initialized = output<T>();

    // ----------[ State ]----------

    protected readonly resolvedValues = computed(() => {
        const values = this.inputs();
        return typeof values === 'function' ? values() : values;
    });

    private componentRef: any;

    // ----------[ View Children ]----------

    private readonly container = viewChild.required('dynamicComponentContainer', {
        read: ViewContainerRef
    });

    // ----------[ Dependencies ]----------

    constructor(private readonly injector: Injector) {}

    // ----------[ Lifecycle ]----------

    public ngAfterViewInit() {
        runInInjectionContext(this.injector, () => {
            effect(() => {
                if (this.componentRef) {
                    this.updateComponentInputs();
                    this.setupComponentOutputs();
                }
            });
        });

        this.component$.subscribe(() => {
            this.createComponent();
        });
    }

    public ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    // ----------[ Methods ]----------

    private createComponent() {
        const container = this.container();
        container.clear();
        const component = this.component();
        if (!component) return;

        this.componentRef = container.createComponent(component);
        this.updateComponentInputs();
        this.setupComponentOutputs();
        this.initialized.emit(this.componentRef.instance);
    }

    private updateComponentInputs() {
        const inputs = this.resolvedValues();
        if (inputs) {
            Object.keys(inputs).forEach((key) => {
                this.componentRef.setInput(key, inputs[key]);
            });
        }
    }

    private setupComponentOutputs() {
        const outputs = this.outputs();
        if (outputs) {
            Object.keys(outputs).forEach((key) => {
                const outputRef = (this.componentRef.instance as any)[key] as OutputRef<any>;
                if (outputRef && typeof outputRef.subscribe === 'function') {
                    outputRef.subscribe((value: any) => {
                        outputs[key](value);
                    });
                }
            });
        }
    }
}
