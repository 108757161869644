import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {
    formatDateRange(
        startDate: Date | string | null,
        endDate: Date | string | null
    ): [string | null, string | null] {
        return [
            this.formatDateToISODate(startDate),
            this.formatDateToISODate(endDate)
        ];
    }

    private formatDateToISODate(date: Date | string | null): string | null {
        if (!date) return null;

        return DateTime.isDateTime(date)
            ? (date as DateTime).toISODate()
            : DateTime.fromJSDate(new Date(date)).toISODate();
    }
}
