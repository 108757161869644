<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-md sm:border">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-6">
        <div class="sm:flex lg:col-span-8 px-4 py-4 sm:px-4 lg:p-4">
            <div
                class="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-20 sm:w-20">
                <img
                    [image]="item().images[0]?.image"
                    [width]="200"
                    [height]="200"
                    class="h-full w-full object-cover object-center sm:h-full sm:w-full"
                    [alt]="item().category.name">
            </div>

            <div class="mt-6 sm:ml-4 sm:mt-0">
                <h3 class="text-lg font-medium text-gray-900">
                    <a href="#">{{ item().equipmentIdentifier }}</a>
                </h3>
                <p class="mt-1 text-sm font-medium text-gray-900">{{ item().category.name }}</p>
                <p class="mt-1 text-sm font-base text-gray-900">{{ item().location.properties.formattedAddress }}</p>
            </div>
        </div>
        <div class="lg:col-span-4 flex flex-col justify-center items-center  border-gray-200 p-2 space-y-2">
            @if(item().status && canManage()) {
                @if(!item().status?.isListedAndAvailable && !item().status?.isListedAndAvailableInFuture) {
                    <span class="isolate inline-flex rounded-md shadow-sm w-full">
                        <button
                            (click)="listClick.emit(item())"
                            type="button"
                            class="w-full relative inline-flex items-center justify-center rounded-md bg-white px-4 py-3 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                            Aanbieden
                        </button>
                    </span>
                } @else {
                    <span class="isolate inline-flex rounded-md shadow-sm w-full">
                        <button
                            (click)="cancelListing()"
                            type="button"
                            class="w-full relative inline-flex items-center justify-center rounded-md bg-white px-4 py-3 text-xs font-semibold text-red-700 ring-1 ring-inset ring-red-600 hover:bg-red-50 focus:z-10">
                            Annuleren
                        </button>
                    </span>
                }
            }
            <span class="isolate inline-flex rounded-md shadow-sm w-full">
                <button
                    [routerLink]="[item().id]"
                    type="button"
                    class="w-full relative inline-flex items-center justify-center rounded-md bg-white px-4 py-3 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                    Wijzigen
                </button>
            </span>
        </div>
    </div>
</div>
