<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-md sm:border hover:bg-gray-50 cursor-pointer">
    <div class="px-4 py-4 sm:px-4 lg:grid lg:grid-cols-12 lg:gap-x-6 lg:p-4">
        <div class="sm:flex lg:col-span-9">
            <div
                class="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-20 sm:w-20">
                <img
                    [image]="item().images[0]?.image"
                    [width]="200"
                    [height]="200"
                    class="h-full w-full object-cover object-center sm:h-full sm:w-full" [alt]="item().category.name">
            </div>

            <div class="mt-6 sm:ml-4 sm:mt-0">
                <h3 class="text-lg font-medium text-gray-900">
                    <a href="#">{{ item().category.name }}</a>
                </h3>
                <p class="mt-1 text-sm font-base text-gray-900">{{ item().location.properties.formattedAddress }}</p>
                <div class="mt-1">
                    <company-public-item-badge [item]="item()"></company-public-item-badge>
                </div>
            </div>
        </div>

        <div class="mt-6 lg:col-span-3 lg:mt-0 flex lg:justify-end items-center">
            <div class="mt-3 sm:mt-0 sm:ml-4 flex items-center">
                <ng-icon
                    name="heroChevronRight"
                    class="text-gray-600 hover:text-gray-900 ml-2"
                />
            </div>
        </div>
    </div>
</div>
