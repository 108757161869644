<div
    (clickOutside)="closeDropdown()"
    class="relative inline-block text-left">
    <div (click)="toggleDropdown()">
        <ng-content select="dropdown-trigger"></ng-content>
    </div>
    @if (isOpen()) {
        <div
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
            tabindex="-1">
            <ng-content select="dropdown-content"></ng-content>
        </div>
    }
</div>
