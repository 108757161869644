<div class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-sm sm:p-6 mx-auto">
    <!-- Close button -->
    <div class="absolute top-0 right-0 pt-4 pr-4">
        <button type="button" (click)="onClose.emit(false)" [disabled]="isLoading"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                [ngClass]="{'opacity-50 cursor-not-allowed': isLoading}">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
        </button>
    </div>

    <div class="sm:flex sm:items-start">
        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"/>
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"/>
            </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Straal van meldingen</h3>
            <div class="mt-2">
                <p class="text-sm text-gray-500">
                    Stel de straal in voor de meldingen die u krijgt, wanneer een aanvraag voldoet aan een item binnen uw inventaris.
                </p>
            </div>
            <form class="mt-5" [formGroup]="formGroup">
                <div class="mb-4">
                    <label class="block text-sm font-medium text-gray-700 mb-1">Straal (in km)</label>
                    <div class="relative">
                        <p-inputNumber [formControlName]="'radius'"
                                       [min]="0"
                                       [max]="350"
                                       [showButtons]="true"
                                       [step]="25"
                                       suffix=" km"
                                       [disabled]="isLoading"
                                       inputStyleClass="w-full py-2 px-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                       styleClass="w-full">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        (click)="onSubmit()"
                        [disabled]="isLoading"
                        [ngClass]="{'opacity-50': isLoading}"
                        class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto"
                    >
                        @if (isLoading) {
                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Bezig met opslaan...
                        } @else {
                            Opslaan
                        }
                    </button>
                    <button
                        type="button"
                        (click)="onClose.emit(false)"
                        [disabled]="isLoading"
                        [ngClass]="{'opacity-50': isLoading}"
                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                        Annuleren
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
