import { PropertyValueInput } from "../../../graphql/generated";

export function compareProperty(id: string, value: PropertyValueInput) {
    return id === getPropertyId(value);
}

export function getPropertyId(value: PropertyValueInput): string | undefined {
    return (
        value.predefined?.propertyId ||
        value.numeric?.propertyId ||
        value.boolean?.propertyId
    );
}