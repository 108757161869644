<div class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-sm sm:p-6 mx-auto">
    <div class="sm:flex sm:items-start">
        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{ item()?.category?.name }} {{ item()?.equipmentIdentifier }} @switch (this.mode()) { @case ('list') { aanbieden } @case ('renew') { verlengen }}</h3>
            <div class="mt-2">
                <p class="text-sm text-gray-500">
                    Vul de gegevens in om het item {{ item()?.category?.name }} {{ item()?.equipmentIdentifier ? "met materieelnummer " + item()?.equipmentIdentifier : '' }} @switch (this.mode()) { @case ('list') { aan te bieden } @case ('renew') { te verlengen }}.
                </p>
            </div>
            <div class="mt-2 rounded-sm bg-yellow-100 p-4 text-sm text-yellow-800">
                <p>Let op, bemande machines en vakmensen moeten elke dag opnieuw worden aangeboden.</p>
            </div>
            <form [formGroup]="listItemForm" (ngSubmit)="listItem()" class="mt-5">
                <div class="grid grid-cols-2 gap-4">
                    <div class="mb-4 col-span-1">
                        <label for="startDate" class="block text-sm font-medium text-gray-700 mb-1">Beschikbaar van (optioneel)</label>
                        <app-date-dropdown id="startDate" [control]="listItemForm.get('startDate')!" placeholder="Per direct"></app-date-dropdown>
                        @if (isFieldInvalid('startDate')) {
                            @if (hasError('startDate', 'pastDate')) {
                                <small class="text-red-500 text-sm">De datum moet in de toekomst liggen.</small>
                            }
                        }
                    </div>
                    <div class="mb-4 col-span-1">
                        <label for="endDate" class="block text-sm font-medium text-gray-700 mb-1">Beschikbaar t/m (optioneel)</label>
                        <app-date-dropdown id="endDate" [minDate]="listItemForm.get('startDate')?.value" [control]="listItemForm.get('endDate')!" placeholder="Onbepaalde tijd"></app-date-dropdown>
                        @if (isFieldInvalid('endDate')) {
                            @if (hasError('endDate', 'pastDate')) {
                                <small class="text-red-500 text-sm">De datum moet in de toekomst liggen.</small>
                            }
                        }
                    </div>
                </div>
                <div class="mb-4 relative">
                    <app-address-search-dropdown
                        (selected)="selectAddressSuggestion($event)"
                        [placeholder]="item()?.location?.properties?.formattedAddress || 'Zoek op adres'"
                    >
                        <div label>
                            <label class="block text-sm font-medium text-gray-700 mb-1">
                                Huidige standplaats
                            </label>
                        </div>
                    </app-address-search-dropdown>
                </div>
                <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button
                        (click)="listItem()"
                        [disabled]="loading()"
                        type="submit"
                        class="inline-flex w-full justify-center rounded-sm bg-blue-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:ml-3 sm:w-auto">
                        Item @switch (this.mode()) { @case ('list') { aanbieden } @case ('renew') { verlengen }}{{ loading() ? '...' : ''}}
                    </button>
                    <button
                        type="button"
                        (click)="cancel()"
                        class="mt-3 inline-flex w-full justify-center rounded-sm bg-white px-3 py-2 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                        Annuleren
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
