import { Component, input } from '@angular/core';
import { SpinnerComponent } from '@knalgeel/pandora';

@Component({
    selector: 'app-loading-state',
    standalone: true,
    imports: [
        SpinnerComponent
    ],
    template: `
        @if (loading()) {
            <div class="flex justify-center py-12">
                <pandora-spinner/>
            </div>
        } @else {
            <ng-content/>
        }`
})
export class LoadingStateComponent {

    public readonly loading = input<boolean>(false);

}
