import { Component, signal } from '@angular/core';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { Util } from 'leaflet';

@Component({
    selector: 'app-dropdown',
    standalone: true,
    imports: [
        ClickOutsideDirective
    ],
    templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
    isOpen = signal<boolean>(false);

    toggleDropdown() {
        this.isOpen.set(!this.isOpen());
    }

    closeDropdown() {
        this.isOpen.set(false);
    }
}
