import {
    Component,
    computed,
    inject,
    Injector,
    input,
    OnInit,
    runInInjectionContext,
    signal,
    Type
} from '@angular/core';
import { WizardStep } from '../../../../types/wizard/wizard-step';
import {
    DynamicDialogContentComponent
} from '../dynamic-component/dynamic-dialog-content.component';
import { PrimaryButtonComponent } from '../../buttons/primary-button/primary-button.component';
import { filter, map, Observable, of, pairwise, switchMap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgIcon } from '@ng-icons/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-wizard',
    standalone: true,
    imports: [
        DynamicDialogContentComponent,
        PrimaryButtonComponent,
        NgIcon
    ],
    templateUrl: './wizard.component.html'
})
export class WizardComponent<T> implements OnInit {

    public readonly title = input.required<string>();
    public readonly steps = input.required<WizardStep<T>[]>();

    // ----------[ Input ]----------
    public readonly initialize = input<(context: T, setStep: (step: number) => void) => void>();
    public readonly complete = input.required<(context: T) => Observable<any>>();
    public readonly service = input.required<Type<any>>();
    public readonly close = input.required<(context: T) => void>();
    protected readonly loading = signal<boolean>(false);
    protected readonly stepIndex = signal<number>(0);

    // ----------[ State ]----------
    protected readonly stepIndex$ = toObservable(this.stepIndex);
    protected readonly currentStep = computed(() => this.steps()[this.stepIndex()]);

    // ----------[ Computed ]----------
    protected readonly currentStep$ = toObservable(this.currentStep);
    protected readonly canPrevious = computed(() => this.stepIndex() > 0);
    private _service: T | null = null;
    protected readonly canNext = computed(() => this.currentStep()?.isComplete(this._service!) && !this.loading());

    // ----------[ Dependencies ]----------

    constructor(
        private readonly injector: Injector
    ) {
    }

    // ----------[ Lifecycle ]----------

    public ngOnInit() {
        this.stepIndex$.pipe(
            pairwise(),
            switchMap(([ prev, next ]: [ number, number ]): Observable<number> => {
                const direction = next > prev ? 'next' : 'previous';
                const step = this.steps()[next];

                return runInInjectionContext(this.injector, () => {
                    if (step.disabled) {
                        return step.disabled(this._service!).pipe(
                            map((disabled: boolean) => {
                                if (disabled) {
                                    return direction === 'next' ? next + 1 : next - 1;
                                }
                                return next;
                            })
                        );
                    }

                    return of(next);
                });
            })
        ).subscribe(step => {
            this.stepIndex.set(step);
        });

        runInInjectionContext(this.injector, () => {
            this._service = inject(this.service());

            if (this.initialize()) {
                this.initialize()!(this._service!, (step: number) => this.stepIndex.set(step));
            }
        });

        this.currentStep$
            .pipe(filter(Boolean))
            .subscribe(step => {
                if (step.initialize) {
                    runInInjectionContext(this.injector, () => {
                        step.initialize!(this._service!, (step: number) => this.stepIndex.set(step));
                    });
                }
            })
    }


    // ----------[ Template Methods ]----------

    protected next(): void {
        this.stepIndex.update(i => i + 1);
    }

    protected previous(): void {
        this.stepIndex.update(i => i - 1);
    }

    protected _complete(): void {
        this.loading.set(true);

        runInInjectionContext(this.injector, () => {
            this.complete()(this._service!)
                .pipe(finalize(() => this.loading.set(false)))
                .subscribe(() => this._close());
        });
    }

    protected _close(): void {
        this.close()(this._service!);
    }

}
