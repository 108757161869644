<div
    (click)="openAddItemWizard()"
    class="group border-b border-t border-gray-300 bg-white shadow-sm sm:rounded-md sm:border cursor-pointer border-dashed h-28 hover:border-gray-400"
>
    <div class="sm:flex items-center justify-center h-full">
        <ng-icon name="heroPlus" class="text-gray-300 mr-1.5 group-hover:text-gray-400"></ng-icon>
        <p class="text-gray-300 font-normal group-hover:text-gray-400">
            Voeg een {{ categoryNameSentenceCase() }} toe
        </p>
    </div>
</div>
