<div class="bg-white text-left rounded-sm border-0 text-gray-900 ring-1 ring-inset ring-gray-300 shadow-md w-[44em]">
    <div class="flex justify-between px-8 py-6 sm:px-6 border-b border-b-gray-200">
        <div class="flex space-x-3">
            <div class="font-bold">
                {{ title() }}
            </div>
        </div>
        <div>
            <ng-icon name="heroXMark" (click)="_close()" class="cursor-pointer" size="1.5em"></ng-icon>
        </div>
    </div>
    <div class="flex-grow min-h-[60vh] max-h-[60vh] overflow-y-auto">
        @if (currentStep(); as component) {
            <app-dynamic-component
                [component]="component.component"
                [inputs]="component.inputs"
            />
        }
    </div>
    <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-8 sm:py-6 border-t border-t-gray-200 gap-3">
        @if (stepIndex() < steps().length - 1) {
            <app-primary-button
                [disabled]="! canNext()"
                (click)="next()"
            >
                Volgende
            </app-primary-button>
        } @else {
            <app-primary-button
                [disabled]="! canNext()"
                (click)="_complete()"
            >
                Voltooien
            </app-primary-button>
        }
        <app-primary-button
            [disabled]="! canPrevious()"
            (click)="previous()"
        >
            Vorige
        </app-primary-button>
    </div>
</div>
