import {Component, input} from '@angular/core';
import {Item} from "../../../../../graphql/generated";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-item-status-badges',
  standalone: true,
    imports: [
        DatePipe
    ],
  templateUrl: './item-status-badges.component.html',
})
export class ItemStatusBadgesComponent {
    item = input.required<Item>();
}
