<!-- Product details -->
<div class="mt-6">
    <nav aria-label="Breadcrumb" class="mb-6 pb-6 border-b border-b-gray-200">
        <ol role="list" class="flex items-center space-x-2">
            <li>
                <div class="flex items-center text-sm">
                    <span class="font-medium text-gray-500">Bedrijfsprofiel</span>
                </div>
            </li>
            <li>
                <div class="flex items-center text-sm">
                    <span class="font-medium text-gray-300 mr-3">{{ company()?.name }}</span>
                </div>
            </li>
        </ol>
    </nav>
    <section aria-labelledby="information-heading" class="mt-4">
        <h2 id="information-heading" class="sr-only">Profieltekst</h2>

        <div class="mt-4 space-y-6 text-md leading-7 text-gray-600" [innerText]="company()?.description">
        </div>
    </section>
</div>

<div class="mx-auto pt-4 mt-4" autoAnimate>
    @if (categories().length === 0 && items().length !== 0) {
        <div class="text-center mt-16">
            <p class="text-gray-500">Geen resultaten gevonden.</p>
        </div>
    }
    @for (category of categories(); track category.id) {
        <div autoAnimate class="mb-5">
            <app-collapsible-header>
                <div title class="">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">{{ category.pluralName }} ({{ category.items.length }})</h3>
                </div>
                <div content>
                    <div class="gap-y-6 gap-x-4 grid grid-cols-2 max-w-screen-2xl mx-auto sm:grid-cols-2" autoAnimate>
                        @for (item of orderItemsByAvailability(category.items); track item.id) {
                            <company-public-item
                                [item]="item"
                                (click)="selectItem(item)"
                            ></company-public-item>
                        }
                    </div>
                </div>
            </app-collapsible-header>
        </div>
    }
</div>

