import { Component } from '@angular/core';
import { AddItemWizardContext } from "../add-item-wizard-context.service";
import { WizardStepComponent } from "../../../../types/wizard/wizard-step";
import {
    CategorySearchDropdownComponent
} from "../../../../core/components/inputs/category-search-dropdown/category-search-dropdown.component";
import { CategorySearchHit, Category } from "../../../../../../graphql/generated";

@Component({
    selector: 'app-select-category',
    standalone: true,
    imports: [
        CategorySearchDropdownComponent
    ],
    templateUrl: './add-item-search-category.component.html',
})
export class AddItemSearchCategoryComponent implements WizardStepComponent<AddItemWizardContext> {

    protected readonly categoryId = this.service.select('categoryId');

    constructor(
        private readonly service: AddItemWizardContext
    ) {}

    protected onCategorySelected(hit: CategorySearchHit) {
        this.service.set('categoryId', hit.category.id);
    }
}
