import {Component, computed, input} from '@angular/core';
import {Item} from "../../../../../../../../graphql/generated";
import {DatePipe, NgOptimizedImage} from "@angular/common";
import {DateTime} from "luxon";
import {ModalService} from "../../../../../../core/services/modal.service";
import {
    CancelListingModalComponent
} from "../cancel-listing-modal/cancel-listing-modal.component";
import {ListItemModalComponent} from "../list-item-modal/list-item-modal.component";
import {ItemStatusBadgesComponent} from "../../../../../components/item-status-badges/item-status-badges.component";
import { ImageDirective } from '../../../../../../core/directives/image.directive';
import { AuthService } from '../../../../../../auth/services/auth.service';

@Component({
    selector: 'company-manage-listing-card',
    standalone: true,
    imports: [
        NgOptimizedImage,
        DatePipe,
        ItemStatusBadgesComponent,
        ImageDirective
    ],
    templateUrl: './manage-listing-card.component.html',
})
export class ManageListingCardComponent {

    public readonly ANALYTICS_ENABLED = false;

    public item = input.required<Item>();

    canManage = computed(() => {
        return this.authService.isAuthenticated() && this.authService.hasSubscriptionAccess();
    });

    constructor(
        private readonly modalService: ModalService,
        private readonly authService: AuthService
    ) {
    }

    public listItem() {
        this.modalService.open({
            component: ListItemModalComponent,
            inputs: {
                item: this.item()
            }
        });
    }

    public renewListing() {
        this.modalService.open({
            component: ListItemModalComponent,
            inputs: {
                item: this.item(),
                mode: 'renew'
            }
        });
    }

    public cancelListing() {
        this.modalService.open({
            component: CancelListingModalComponent,
            inputs: {
                item: this.item()
            }
        });
    }
}
